/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

import * as React from 'react';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import * as moment from 'moment';

import { Table, Pagination, Icon, Label, Loader } from 'semantic-ui-react'

import { BaseComponent } from '../components'
import { DomainStore } from '../../stores/domain.store';
import { AsyncState } from '../../models/async.models';
import { Certificate, SyncState, isSyncPhaseInProgress, SyncPhase, isSyncPhaseFailed } from '../../models/domain.models';
import { I18N } from '../../i18n';
import { C } from '../../c';

type Props = {
    domainStore: DomainStore
}
type State = {
    searchState: AsyncState<Certificate[]>
    syncState: AsyncState<SyncState[]>
    page: number
}

@inject('domainStore')
@observer
export class CertificateListScreen extends BaseComponent<Props, State> {

    private runningOnMobile = navigator.userAgent.toLowerCase().search("iphone") > -1

    constructor(props: Props) {
        super(props);

        this.state = {
            searchState: props.domainStore.searchCertificatesState,
            syncState: props.domainStore.syncCertificatesState,
            page: 1
        }
    }

    componentDidMount() {
        document.title = I18N.common_certificates

        this.disposables = [
            reaction(
                () => this.props.domainStore.searchCertificatesState,
                (searchState) => {
                    this.setState({
                        searchState,
                        page: 1
                    });
                }),
            reaction(
                () => this.props.domainStore.syncCertificatesState,
                (syncState) => {
                    if (this.state.syncState.isInProgress() &&
                        syncState.isSuccessful()) {
                        // && print is waiting
                    }
                    this.setState({ syncState });
                }),
        ];
    }

    render() {
        return this.asyncStateWidget(this.state.searchState, {
            onFail: (error) => this.asyncErrorMessage(error),
            onSuccess: (value) => this.renderTable(value),
        });
    }

    private renderTable(certificates: Certificate[]) {
        const pageCount = Math.ceil(certificates.length / C.CERTIFICATES_PER_PAGE)
        const footer = pageCount > 1 ? (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='8' className='right aligned'>
                        <Pagination
                            size='tiny'
                            activePage={this.state.page}
                            totalPages={pageCount}
                            nextItem={false}
                            prevItem={false}
                            siblingRange={1}
                            boundaryRange={0}
                            ellipsisItem={false}
                            onPageChange={(_, data) => this.setState({
                                page: data.activePage as number
                            })} />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        ) : null

        const startIndex = (this.state.page - 1) * C.CERTIFICATES_PER_PAGE
        return (
            <Table celled size='small'>
                <Table.Header className='hideOnMobile'>
                    <Table.Row>
                        <Table.HeaderCell>{I18N.certificate_contractNumber}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.certificate_certificateNumber}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.certificate_collectionMethod}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.certificate_issueDate}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.certificate_deliveryDate}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.certificate_syncDate}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.certificate_viewPdfUri}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.certificate_webpageUri}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        certificates
                            .slice(startIndex, startIndex + C.CERTIFICATES_PER_PAGE)
                            .map(certificate => (
                                <Table.Row key={'certificateRow' + certificate.id}
                                    style={{
                                        color: certificate.specs == true ||
                                            this.state.syncState.value &&
                                            this.state.syncState.value.findIndex((s) =>
                                                s.contractNumber === certificate.contractNumber &&
                                                s.phase === SyncPhase.COMPLETE) >= 0
                                            ? 'black' : 'grey'
                                    }}>
                                    <Table.Cell>
                                        {certificate.contractNumber}
                                        {this.state.syncState.isInProgress() &&
                                            this.state.syncState.value.findIndex((s) =>
                                                s.contractNumber === certificate.contractNumber &&
                                                isSyncPhaseInProgress(s.phase)) >= 0
                                            ? <Loader active inline size='mini' style={{ marginLeft: 5 }} />
                                            : this.state.syncState.isSuccessful() &&
                                                this.state.syncState.value.findIndex((s) =>
                                                    s.contractNumber === certificate.contractNumber &&
                                                    isSyncPhaseFailed(s.phase)) >= 0
                                                ? <Icon color='red' name='warning sign' style={{ marginLeft: 5 }} />
                                                : null}
                                    </Table.Cell>
                                    <Table.Cell>{certificate.certificateNumber}</Table.Cell>
                                    <Table.Cell>{certificate.collectionMethod}</Table.Cell>
                                    <Table.Cell>{certificate.issueDate}</Table.Cell>
                                    <Table.Cell>{certificate.deliveryDate}</Table.Cell>
                                    <Table.Cell>
                                        {moment(certificate.syncDate).format('YYYY/MM/DD')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a className='download-pdf-link'
                                            href={C.API_BASE_URI + '/certificates/pdf/' +
                                                certificate.contractNumber +
                                                (this.runningOnMobile ? '?download=1' : '')}
                                            target={this.runningOnMobile ? '_self' : '_blank'}>
                                            {I18N.certificate_openPdfLink}
                                        </a>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a className='open-webpage-link'
                                            href={certificate.webpageUri}
                                            target={this.runningOnMobile ? '_self' : '_blank'}>
                                            {I18N.certificate_openWebpageLink}
                                        </a>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                    }
                </Table.Body>
                {footer}
            </Table>
        )
    }
}