/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */
import { Observable, Observer, Subject, ReplaySubject } from 'rxjs';

import { Contract, Certificate, SyncProgress, UploadProgress, Auth } from '../models/domain.models';
import { C } from '../c';
import { NetworkProblems, ServerError, AuthError } from './api.errors';

export class DomainApi {
    private progressObservable: Observable<MessageEvent> =
        Observable.create((observer: Observer<MessageEvent>) => {
            var keepAlive = true
            var ws: WebSocket
            const connect = () => {
                console.log('connecting to ' + C.API_WS_URI)
                ws = new WebSocket(C.API_WS_URI)
                ws.onopen = () => {
                    console.log('ws connection established')
                }
                ws.onmessage = (event) => {
                    observer.next(event)
                }
                ws.onerror = (event) => {
                    console.log('ws error', event)
                }
                ws.onclose = (event) => {
                    if (keepAlive) {
                        console.log('ws connection closed when keepAlive',
                            event.code, event.reason)
                        ws.onmessage = () => null
                        ws.onclose = () => null
                        setTimeout(connect, 1000);
                    } else {
                        observer.complete()
                    }
                }
            }
            connect()

            return () => {
                if (ws) {
                    keepAlive = false
                    ws.close()
                }
            }
        }).share()

    private catchNetworkProblems(error: any): Observable<Response> {
        if (error instanceof TypeError && error.message == 'Failed to fetch')
            return Observable.throw(new NetworkProblems());
        return Observable.throw(error);
    }

    private json(res: Response, auth?: Auth): Observable<any> {
        if (auth && res.status === 403) {
            auth.onRejected()
            return Observable.throw(new AuthError());
        }
        if (res.status != 200)
            return Observable.throw(new ServerError());
        return Observable.fromPromise(res.json())
    }

    searchCertificates(auth: Auth, query?: string): Observable<Certificate[]> {
        let uri = C.API_BASE_URI + '/certificates'
        if (query)
            uri += `?search=${encodeURIComponent(query)}`
        let headers = { 'X-Secret': auth.secret }

        return Observable.fromPromise(fetch(uri, { headers }))
            ._catch(this.catchNetworkProblems)
            .flatMap(res => this.json(res, auth))
            .map(json => {
                return json.data.map(item => {
                    const certificate: Certificate = {
                        id: item.id,
                        contractNumber: item.contract_number,
                        certificateNumber: item.certificate_number,
                        collectionMethod: item.collection_method,
                        syncDate: new Date(item.sync_date),
                        issueDate: item.issue_date,
                        deliveryDate: item.delivery_date,
                        webpageUri: item.webpage_uri,
                        viewPdfUri: item.view_pdf_uri,
                        downloadPdfUri: item.download_pdf_uri,
                        specs: item.specs,
                    }
                    return certificate;
                })
            })
    }

    syncCertificates(auth: Auth, numbers: string[]): Observable<boolean> {
        return Observable.fromPromise(fetch(C.API_BASE_URI + '/certificates', {
            method: 'POST',
            headers: {
                'X-Secret': auth.secret,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ numbers })
        }))
            ._catch(this.catchNetworkProblems)
            .flatMap(res => this.json(res, auth))
            .map(json => json.status === 'RUNNING')
    }

    listenSyncCertificatesProgress(): Observable<SyncProgress> {
        return this.progressObservable
            .map(event => JSON.parse(event.data))
            .filter(data =>
                ['SYNC_COMPLETE', 'SYNC_ERROR', 'SYNC_PROGRESS']
                    .includes(data.type))
    }

    searchContracts(auth: Auth, query?: string): Observable<Contract[]> {
        let uri = C.API_BASE_URI + '/contracts'
        if (query)
            uri += `?search=${encodeURIComponent(query)}`
        let headers = { 'X-Secret': auth.secret }

        return Observable.fromPromise(fetch(uri, { headers }))
            ._catch(this.catchNetworkProblems)
            .flatMap(res => this.json(res, auth))
            .map(json => {
                return json.data.map(item => {
                    const contract: Contract = {
                        id: item.id,
                        contractTimestamp: item.contract_timestamp,
                        downloadPdfUri: item.download_pdf_uri,
                        syncDate: new Date(item.sync_date)
                    }
                    return contract;
                })
            })
    }

    uploadContracts(auth: Auth, files: File[]): Observable<boolean> {
        const data = new FormData()
        files.forEach(file => data.append(file.name, file))
        let headers = { 'X-Secret': auth.secret }

        return Observable.fromPromise(fetch(C.API_BASE_URI + '/contracts', {
            method: 'POST',
            body: data,
            headers,
        }))
            ._catch(this.catchNetworkProblems)
            .flatMap(res => this.json(res, auth))
            .map(json => json.status === 'RUNNING')
    }

    listenUploadContractsProgress(): Observable<UploadProgress> {
        return this.progressObservable
            .map(event => JSON.parse(event.data))
            .filter(data =>
                ['UPLOAD_COMPLETE', 'UPLOAD_ERROR', 'UPLOAD_PROGRESS']
                    .includes(data.type))
    }

    saveSyncHistory(auth:Auth, code: string, query: string) {
        return Observable.fromPromise(fetch(C.API_BASE_URI + '/sync_history', {
            method: 'POST',
            headers: {
                'X-Secret': auth.secret,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, query })
        }))
            ._catch(this.catchNetworkProblems)
    }

    getSyncHistory(auth:Auth) {
        return Observable.fromPromise(fetch(C.API_BASE_URI + '/sync_history', {
            headers: {
                'X-Secret': auth.secret,
                'Content-Type': 'application/json',
            },
        }))
            ._catch(this.catchNetworkProblems)
            .flatMap(res => this.json(res, auth))
            .map(({data}) => data)
    }
}