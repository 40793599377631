/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

import { DomainStore } from "./domain.store";
import { Api } from "../api";

export function createStores(api: Api) {
    return {
        domainStore: new DomainStore(api),
    }
}