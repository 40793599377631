/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

export class Auth {
    secret: string
    onRejected: () => void
}

export type Certificate = {
    id?: number
    contractNumber: string
    certificateNumber: string
    collectionMethod: string
    syncDate: Date
    issueDate: string
    deliveryDate: string
    webpageUri: string
    viewPdfUri: string
    downloadPdfUri: string
    specs?: boolean
}

export type Contract = {
    id?: number
    contractTimestamp: number
    syncDate: Date
    downloadPdfUri: string
}

export enum SyncPhase {
    AWAITS = 'AWAITS',
    PARSING_WEBPAGE = 'PARSING_WEBPAGE',
    DOWNLOADIN_PDF = 'DOWNLOADIN_PDF',
    PARSING_PDF = 'PARSING_PDF',
    STORING_DATA = 'STORING_DATA',
    COMPLETE = 'COMPLETE',
    DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
    CONNECTION_PROBLEMS = 'CONNECTION_PROBLEMS',
    TIMEOUT_ERROR = 'TIMEOUT_ERROR',
    ERROR = 'ERROR'
}

export const isSyncPhaseInProgress = (phase: SyncPhase) =>
    phase == SyncPhase.PARSING_WEBPAGE ||
    phase == SyncPhase.DOWNLOADIN_PDF ||
    phase == SyncPhase.PARSING_PDF ||
    phase == SyncPhase.STORING_DATA

export const isSyncPhaseFailed = (phase: SyncPhase) =>
    phase == SyncPhase.ERROR ||
    phase == SyncPhase.TIMEOUT_ERROR ||
    phase == SyncPhase.CONNECTION_PROBLEMS ||
    phase == SyncPhase.DOCUMENT_NOT_FOUND

export type SyncState = {
    contractNumber: string
    phase: SyncPhase
}

export type SyncProgress = {
    type: 'SYNC_BLANK' | 'SYNC_COMPLETE' | 'SYNC_ERROR' | 'SYNC_PROGRESS'
    results?: SyncState[]
}

export enum UploadPhase {
    AWAITS = 'AWAITS',
    UPLOADIN_PDF = 'UPLOADIN_PDF',
    PARSING_PDF = 'PARSING_PDF',
    STORING_DATA = 'STORING_DATA',
    COMPLETE = 'COMPLETE',
    TIMEOUT_ERROR = 'TIMEOUT_ERROR',
    ERROR = 'ERROR'
}

export type UploadState = {
    contractTimestamp: number
    phase: UploadPhase
}

export type UploadProgress = {
    type: 'UPLOAD_BLANK' | 'UPLOAD_COMPLETE' | 'UPLOAD_ERROR' | 'UPLOAD_PROGRESS'
    results?: UploadState[]
}