/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

import * as React from 'react';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import * as moment from 'moment';

import { Table, Pagination, Container } from 'semantic-ui-react'

import { BaseComponent } from '../components'
import { DomainStore } from '../../stores/domain.store';
import { AsyncState } from '../../models/async.models';
import { Contract } from '../../models/domain.models';
import { I18N } from '../../i18n';
import { C } from '../../c';

type Props = {
    domainStore: DomainStore
}
type State = {
    searchState: AsyncState<Contract[]>
    page: number
}

@inject('domainStore')
@observer
export class ContractListScreen extends BaseComponent<Props, State> {

    private runningOnMobile = navigator.userAgent.toLowerCase().search("iphone") > -1

    constructor(props: Props) {
        super(props);

        this.state = {
            searchState: props.domainStore.searchContractsState,
            page: 1
        }
    }

    componentDidMount() {
        document.title = I18N.common_contacts

        this.disposables = [
            reaction(
                () => this.props.domainStore.searchContractsState,
                (searchState) => {
                    this.setState({
                        searchState,
                        page: 1
                    });
                }),
        ];
    }

    render() {
        return this.asyncStateWidget(this.state.searchState, {
            onFail: (error) => this.asyncErrorMessage(error),
            onSuccess: (value) => this.renderTable(value),
        });
    }

    private renderTable(contracts: Contract[]) {
        const pageCount = Math.ceil(contracts.length / C.CONTRACTS_PER_PAGE)
        const footer = pageCount > 1 ? (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='8' className='right aligned'>
                        <Pagination
                            size='tiny'
                            activePage={this.state.page}
                            totalPages={pageCount}
                            nextItem={false}
                            prevItem={false}
                            siblingRange={1}
                            boundaryRange={0}
                            ellipsisItem={false}
                            onPageChange={(_, data) => this.setState({
                                page: data.activePage as number
                            })} />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        ) : null

        const startIndex = (this.state.page - 1) * C.CONTRACTS_PER_PAGE
        return (
            <Table celled size='small'>
                <Table.Header className='hideOnMobile'>
                    <Table.Row>
                        <Table.HeaderCell>{I18N.contract_timestamp}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.contract_syncDate}</Table.HeaderCell>
                        <Table.HeaderCell>{I18N.contract_downloadPdfUri}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        contracts
                            .slice(startIndex, startIndex + C.CONTRACTS_PER_PAGE)
                            .map(contract => (
                                <Table.Row key={'contractRow' + contract.id}>
                                    <Table.Cell>{contract.contractTimestamp}</Table.Cell>
                                    <Table.Cell>
                                        {moment(contract.syncDate).format('YYYY/MM/DD')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a href={contract.downloadPdfUri}
                                            target={this.runningOnMobile ? '_self' : '_blank'}>
                                            {I18N.contract_openPdfLink}
                                        </a>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                    }
                </Table.Body>
                {footer}
            </Table>
        )
    }
}