/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */
import 'semantic-ui-css/semantic.min.css';
import '../main.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'mobx-react';
import { Container } from 'semantic-ui-react'

import { AppBar } from './views/components/app_bar'
import { CertificateListScreen } from './views/screens/certificates.screen';
import { SyncContractsScreen } from './views/screens/sync.screen';
import { Api } from './api';
import { createStores } from './stores';
import { UploadScreen } from './views/screens/upload.screen';
import { ContractListScreen } from './views/screens/contracts.screen';

const api = new Api();
const stores = createStores(api);

class App extends React.Component {
    render() {
        return (
            <Provider {...stores}>
                <BrowserRouter>
                    <Container id='screen'>
                        <AppBar />
                        <Route exact path="/" component={CertificateListScreen} />
                        <Route path="/certificates" component={CertificateListScreen} />
                        <Route path="/contracts" component={ContractListScreen} />
                        <Route path="/sync" component={SyncContractsScreen} />
                        <Route path="/upload" component={UploadScreen} />
                    </Container>
                </BrowserRouter>
            </Provider>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('content'));