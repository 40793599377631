/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

export namespace C {
    export const BACKEND_HOST = !DEV_MODE ? 'pdf.xy-100.com' : '127.0.0.1'
    export const BACKEND_PORT = 3001
    export const API_BASE_URI = `https://${BACKEND_HOST}:${BACKEND_PORT}/api/v2`
    export const API_WS_URI = `wss://${BACKEND_HOST}:${BACKEND_PORT}`

    export const CERTIFICATES_PER_PAGE = 50
    export const CONTRACTS_PER_PAGE = CERTIFICATES_PER_PAGE
    export const SYNC_STATES_PER_PAGE = CERTIFICATES_PER_PAGE
    export const UPLOADT_STATES_PER_PAGE = CERTIFICATES_PER_PAGE
}