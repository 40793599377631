import { AsyncError, AsyncErrorType } from "../models/async.models";
import { SyncPhase, UploadPhase } from "../models/domain.models";
import { count } from "../../node_modules/rxjs/operators";

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

export class I18N {
    static common_appName = 'PDF Search'
    static common_documents = 'Documents'
    static common_certificates = 'Certificates'
    static common_contacts = 'Contracts'
    static common_sync = 'Sync'
    static common_syncCertificates = 'Sync Certificates'
    static common_uploadContracts = 'Upload Contracts'
    static common_upload = 'Upload'
    static common_search = 'Search'
    static common_searchPlaceholder = 'Search...'
    static common_syncPlaceholder = 'Contract numbers (1,2,3 or 1-3)'

    static certificate_contractNumber = '合同号'
    static certificate_certificateNumber = '证书号'
    static certificate_collectionMethod = '收集方式'
    static certificate_syncDate = 'Sync Date'
    static certificate_issueDate = '签发日期'
    static certificate_deliveryDate = '交货日期'
    static certificate_webpageUri = 'Webpage'
    static certificate_viewPdfUri = '查看PDF详情'
    static certificate_openPdfLink = 'PDF'
    static certificate_openWebpageLink = 'Webpage'

    static contract_timestamp = 'Name'
    static contract_syncDate = I18N.certificate_syncDate
    static contract_downloadPdfUri = I18N.certificate_viewPdfUri
    static contract_openPdfLink = 'PDF'

    static searchCertificate_resultMessage = (number) =>
        number == 1 ? '1 certificate found' : `${number} certificates found`
    static searchCertificate_emptyQueryError = 'Enter contract numbers range'
    static searchCertificate_invalidQueryError = {
        message: 'Invalid contract numbers format, use:',
        example1: 'list (1,2,3,99)',
        example2: 'range (1-99)'
    }

    static searchContract_resultMessage = (number) =>
        number == 1 ? '1 contract found' : `${number} contracts found`

    static syncCertificate_confirmSyncMessage = (query, number) =>
        `Are you sure you want to synchronize ${query} certificate(s). Total number is ${number}.`
    static syncCertificate_syncFinishedMessage = (count) =>
        `Sync complete with ${count} successfully processed certificates`

    static syncCertificate_contractNumber = I18N.certificate_contractNumber
    static syncCertificate_phase = 'Status'
    static syncCertificate_progress = 'Progress'
    static syncCertificate_fromSyncPhase = (phase: SyncPhase | UploadPhase) => {
        switch (phase) {
            case SyncPhase.AWAITS:
                return 'Waiting...'
            case SyncPhase.PARSING_WEBPAGE:
                return 'Parsing webpage'
            case UploadPhase.UPLOADIN_PDF:
                return 'Uploading PDF'
            case SyncPhase.DOWNLOADIN_PDF:
                return 'Downloading PDF'
            case SyncPhase.PARSING_PDF:
                return 'Parsing PDF'
            case SyncPhase.STORING_DATA:
                return 'Storing result'
            case SyncPhase.COMPLETE:
                return 'Complete'
            case SyncPhase.DOCUMENT_NOT_FOUND:
                return 'Certificate not found'
            case SyncPhase.TIMEOUT_ERROR:
                return 'Timeout'
            case SyncPhase.CONNECTION_PROBLEMS:
                return 'Failed to connect to esales.baosteel.com'
            default:
                return 'Server error'
        }
    }
    static uploadContract_phase = I18N.syncCertificate_phase
    static uploadContract_progress = I18N.syncCertificate_progress
    static uploadContract_noSuitableFilesError = 
        'No suitable pdf files selected'
    static uploadContract_confirmMessage = (count) =>
        `Are you sure you want to upload ${count} contracts(s)?`
    static uploadContract_finishMessage = (count) =>
        `${count} contract(s) uploaded successfully`

    static asyncError(error: AsyncError): { title: string, subtitle: string } {
        switch (error.type) {
            case AsyncErrorType.NETWORK_PROBLEMS:
                return {
                    title: 'Network problems',
                    subtitle: 'Please, check your internet connection'
                }
            case AsyncErrorType.SERVER_ERROR:
                return {
                    title: 'Server Error',
                    subtitle: 'Please, try again later'
                }
            case AsyncErrorType.AUTH_ERROR:
                return {
                    title: 'Invalid Secret Key',
                    subtitle: 'Reload page to continue'
                }
            default:
                return {
                    title: 'Application Error',
                    subtitle: 'Please, contact your system administrator'
                }
        }
    }
}