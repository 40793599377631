/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

import * as React from 'react';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import { History } from 'history'

import {
    Container, Menu, Image, Icon, Input, Popup,
    Dropdown, Loader, Button, Modal, Header, DropdownDivider, Pagination, Grid, List, Label
} from 'semantic-ui-react'

import { I18N } from '../../i18n'
import { DomainStore } from '../../stores/domain.store';
import { AsyncState } from '../../models/async.models';
import {
    Certificate, SyncState, UploadState, Contract, Auth
} from '../../models/domain.models';
import { BaseComponent } from '.';
import { C } from '../../c';

type Props = {
    history?: History
    domainStore: DomainStore
}
type State = {
    auth: Auth,
    searchCertificatesState: AsyncState<Certificate[]>
    searchContractsState: AsyncState<Contract[]>
    syncCertificatesState: AsyncState<SyncState[]>
    uploadContractsState: AsyncState<UploadState[]>
    searchCertificatesQuery: string
    searchContractsQuery: string
    syncCertificatesCode: string
    syncCertificatesQuery: string
    syncCertificatesQueryError: string | JSX.Element,
    searchCertificateResultMessage: string
    searchContractResultMessage: string
    printCertificatesAfterSync: Certificate[]
    activePage: number
    visiblePages: number []
}

@inject('domainStore')
@observer
class AppBarComponent extends BaseComponent<Props, State> {

    private runningOnMobile = navigator.userAgent.toLowerCase().search("iphone") > -1
    private uploadInput: HTMLInputElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            auth: props.domainStore.auth,
            searchCertificatesState: props.domainStore.searchCertificatesState,
            searchContractsState: props.domainStore.searchContractsState,
            syncCertificatesState: props.domainStore.syncCertificatesState,
            uploadContractsState: props.domainStore.uploadContractsState,
            searchCertificatesQuery: '50.00mm×6.5',
            searchContractsQuery: '',
            syncCertificatesCode: 'G6H66',
            syncCertificatesQuery: '',
            syncCertificatesQueryError: null,
            searchCertificateResultMessage: null,
            searchContractResultMessage: null,
            printCertificatesAfterSync: [],
            activePage: 1,
            visiblePages: [],
        }
    }

    componentDidMount() {
        this.disposables = [
            reaction(
                () => this.props.domainStore.auth,
                (auth) => this.setState({ auth })
            ),
            reaction(
                () => this.props.domainStore.searchCertificatesState,
                (searchCertificatesState) => {
                    this.setState({
                        searchCertificatesState,
                        searchCertificateResultMessage:
                            searchCertificatesState.isSuccessful()
                                ? I18N.searchCertificate_resultMessage(
                                    searchCertificatesState.value.length)
                                : null
                    });
                }),
            reaction(
                () => this.props.domainStore.searchContractsState,
                (searchContractsState) => {
                    this.setState({
                        searchContractsState,
                        searchContractResultMessage:
                            searchContractsState.isSuccessful()
                                ? I18N.searchContract_resultMessage(
                                    searchContractsState.value.length)
                                : null
                    });
                }),
            reaction(
                () => this.props.domainStore.syncCertificatesState,
                (syncCertificatesState) => {
                    if (this.state.printCertificatesAfterSync.length &&
                        syncCertificatesState.isSuccessful() &&
                        this.state.syncCertificatesState.isInProgress()) {
                        this.printSertificatesSpecs(this.state.printCertificatesAfterSync, false)
                        this.state.printCertificatesAfterSync.splice(0)
                    }
                    if (this.state.printCertificatesAfterSync.length &&
                        syncCertificatesState.isFailed() &&
                        this.state.syncCertificatesState.isInProgress()) {
                        this.state.printCertificatesAfterSync.splice(0)
                    }
                    let syncCertificatesCode = this.state.syncCertificatesCode,
                        syncCertificatesQuery = this.state.syncCertificatesQuery;
                    if (syncCertificatesState.isSuccessful() && syncCertificatesState.value.length) {
                        syncCertificatesCode = syncCertificatesState.value[0].contractNumber.substring(0, 5)
                        try {
                            let min = 9999999, max = 0
                            syncCertificatesState.value.forEach(s => {
                                const num = parseInt(s.contractNumber.substring(5))
                                if (min > num) min = num
                                if (max < num) max = num
                            })
                            syncCertificatesQuery = `${min}-${max}`
                        } catch (e) {
                            console.error('failed to parse contractNumbers range')
                        }
                    }
                    this.setState({ syncCertificatesState, syncCertificatesCode, syncCertificatesQuery });
                }),
            reaction(
                () => this.props.domainStore.uploadContractsState,
                (uploadContractsState) => {
                    this.setState({ uploadContractsState });
                }),
        ];
    }
    componentWillUpdate() {
        this.props.domainStore.updateSyncHistory()
    }

    private searchCertificates() {
        if (this.state.searchCertificatesState.isInProgress())
            return

        this.props.domainStore.searchCertificates(this.state.searchCertificatesQuery)
    }

    private searchContracts() {
        if (this.state.searchContractsState.isInProgress())
            return

        this.props.domainStore.searchContracts(this.state.searchContractsQuery)
    }

    private printSertificatesSpecs(certs: Certificate[], syncWithoutSpecs: boolean) {
        if (syncWithoutSpecs) {
            const noSpecs = certs.filter(s => !s.specs).map(s => s.contractNumber)
            if (noSpecs.length) {
                if (this.state.syncCertificatesState.isInProgress()) {
                    alert('Sync already in progress, try again later...')
                    return
                }
                this.props.domainStore.syncCertificates(noSpecs)
                this.setState({ printCertificatesAfterSync: certs })
                return
            }
        }
        const uri = C.API_BASE_URI + '/certificates/specs?format=print&ids=' +
            certs.map(s => s.id).join(',')
        window.open(uri, '_self');
    }

    private syncCertificates() {
        if (this.state.syncCertificatesState.isInProgress())
            return

        const query = this.state.syncCertificatesQuery.trim()
        if (query.length == 0) {
            return this.setState({ syncCertificatesQueryError: I18N.searchCertificate_emptyQueryError })
        }

        const numbers: string[] = []
        if (/^\d+$/.test(query)) {
            numbers.push(query)
        } else if (/^\d+-\d+$/.test(query)) {
            const range = query.split('-')
            for (var i = parseInt(range[0]); i <= parseInt(range[1]); i++) {
                numbers.push(i.toString())
            }
        } else if (/^\d+(,\d+)*$/.test(query)) {
            numbers.push(...query.split(','))
        }
        if (numbers.length == 0) {
            return this.setState({
                syncCertificatesQueryError: (
                    <div>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {I18N.searchCertificate_invalidQueryError.message}
                        </span>
                        <li>{I18N.searchCertificate_invalidQueryError.example1}</li>
                        <li>{I18N.searchCertificate_invalidQueryError.example2}</li>
                    </div>
                )
            })
        }

        const contractNumbers = numbers.map(n => ('0000' + n).substr(-5))
            .map(n => this.state.syncCertificatesCode + n)
        if (confirm(I18N.syncCertificate_confirmSyncMessage(query, contractNumbers.length))) {
            this.props.domainStore.syncCertificates(contractNumbers)
            this.props.domainStore.saveSyncHistory(this.state.syncCertificatesCode, query)
        }
    }

    private upload(fileList: FileList) {
        const files: File[] = []
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i)
            if (/^\d{13}.pdf$/.test(file.name))
                files.push(file)
        }
        if (!files.length)
            return alert(I18N.uploadContract_noSuitableFilesError)
        if (confirm(I18N.uploadContract_confirmMessage(files.length)))
            this.props.domainStore.uploadContracts(files)
    }

    private filterPages(visiblePages, totalPages) {
        return visiblePages.filter(page => page <= totalPages);
    };

    private getVisiblePages(page, total) {
        if (total < 7) {
          return this.filterPages([1, 2, 3, 4, 5, 6], total);
        } else {
          if (page % 5 >= 0 && page > 4 && page + 2 < total) {
            return [1, page - 1, page, page + 1, total];
          } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total - 3, total - 2, total - 1, total];
          } else {
            return [1, 2, 3, 4, 5, total];
          }
        }
    };

    private renderToolsPanel() {
        if (this.props.history.location.pathname == '/upload') {
            const uploading = this.state.uploadContractsState.isInProgress()
            return (
                <Menu.Menu position='right'>
                    <input type="file" multiple
                        ref={(ref) => {
                            if (ref) {
                                (ref as any).directory = true;
                                (ref as any).webkitdirectory = true;
                                this.uploadInput = ref
                            }
                        }}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                            this.upload(event.target.files)
                            event.target.value = ''
                        }}
                    />
                    <Menu.Item
                        icon={uploading ? null : 'upload'}
                        name={I18N.common_upload}
                        onClick={() => this.uploadInput.click()}>
                        {
                            uploading ? <Loader active inline size='tiny' /> : null
                        }
                    </Menu.Item>
                </Menu.Menu>
            )
        } else if (this.props.history.location.pathname == '/sync') {
            const onPage = 7;
            const { syncHistory } = this.props.domainStore
            const { syncCertificatesCode, activePage } = this.state
            const selectedCodeHistory = (syncHistory.find(({code}) => code === syncCertificatesCode) || {periods: [] }).periods
            const length = selectedCodeHistory.length
            const pagesCount = ((length -(length % onPage)) / onPage) + (length % onPage === 0 ? 0: 1)
            const visiblePages = this.getVisiblePages(activePage, pagesCount)

            return (
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Popup
                            trigger={
                                <Input id='syncInput'>
                                    <Dropdown item search selection
                                            id='syncInputCode'
                                            icon={null}
                                            noResultsMessage={null}
                                            disabled={this.state.syncCertificatesState.isInProgress()}
                                            options={this.props.domainStore.syncHistory
                                                .map(item => item.code)
                                                .map((s, i) => ({ key: i, value: s, text: s }))}
                                            searchInput={
                                                <Dropdown.SearchInput
                                                    autoComplete='off'
                                                    value={this.state.syncCertificatesCode}
                                                    disabled={this.state.syncCertificatesState.isInProgress()}
                                                    onChange={(_, data) =>
                                                        this.setState({ syncCertificatesCode: data.value, activePage: 1})}
                                                    onBlur={() => {
                                                        if (!this.state.syncCertificatesCode)
                                                            this.setState({ syncCertificatesCode: 'G6H66' })
                                                    }}
                                                />
                                            }
                                            onChange={(_, data) => {
                                                if (data.value) {
                                                    this.setState(s => ({
                                                        syncCertificatesCode: data.value as string,
                                                        activePage: 1,
                                                        syncCertificatesQuery: '',
                                                    }))
                                                }
                                            }} >
                                        </Dropdown>
                                        <Dropdown item search selection
                                            id='syncInputPeriod'
                                            icon={null}
                                            noResultsMessage={null}
                                            disabled={this.state.syncCertificatesState.isInProgress()}
                                            searchInput={
                                                <Dropdown.SearchInput
                                                    autoComplete='off'
                                                    value={this.state.syncCertificatesQuery}
                                                    disabled={this.state.syncCertificatesState.isInProgress()}
                                                    onChange={(_, data) =>{
                                                        return  this.setState({ syncCertificatesQuery: data.value as string})
                                                    }}
                                                />
                                            }
                                            onKeyUp={(event) => {
                                                event.preventDefault();
                                                if (event.keyCode === 13)
                                                    this.syncCertificates();
                                            }}
                                            style={{
                                                borderRadius: 0
                                            }}
                                            >
                                        <Dropdown.Menu>
                                            {
                                               selectedCodeHistory
                                                    .sort((a: any, b: any): any => new Date(b.date).getTime() - new Date(a.date).getTime())
                                                    .slice((activePage - 1) * onPage, activePage * onPage)
                                                    .map(obj=> <Dropdown.Item>
                                                        <span>{obj.period}</span>
                                                        <span style={{color:'#c4c4c4', float:"right", fontSize: 10}}>{new Date(obj.date).toDateString()}</span>
                                                    </Dropdown.Item>)
                                            }
                                                <Dropdown.Item>
                                                    {
                                                        visiblePages.map((page, index, array) => {
                                                            return <span>
                                                                {array[index - 1] + 2 < page ? ' ... ' : ''}
                                                                <span
                                                                style={{
                                                                    marginLeft: 2,
                                                                    marginRight:2,
                                                                    paddingLeft:3,
                                                                    paddingRight:3,
                                                                    border: '1px solid #c4c4c4',
                                                                    borderRadius: 2,
                                                                    backgroundColor: activePage === page? '#c4c4c4' : '#e0e0e0'
                                                                }}
                                                                onClick={()=> this.setState({activePage: page})}
                                                            >
                                                                {page}
                                                            </span>
                                                            </span>
                                                        })
                                                    }
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    <Button
                                        icon
                                        onClick={() => this.syncCertificates()}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        }}
                                    >
                                        <Icon name={this.state.syncCertificatesState.isInProgress()? null: 'sync'}/>
                                    </Button>
                                    </Input>
                            }
                            content={
                                <div style={{ display: 'flex' }}>
                                    <Icon name='warning circle' style={{ paddingTop: 2 }} />
                                    {this.state.syncCertificatesQueryError}
                                </div>
                            }
                            on='click'
                            open={this.state.syncCertificatesQueryError != null}
                            onClose={() => this.setState({ syncCertificatesQueryError: null })}
                            position='bottom center'
                            inverted
                            hideOnScroll
                        />
                    </Menu.Item>
                </Menu.Menu>
            )
        } else if (this.props.history.location.pathname == '/contracts') {
            return (
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Popup
                            trigger={
                                <Input id='searchInput'
                                    value={this.state.searchContractsQuery}
                                    loading={this.state.searchContractsState.isInProgress()}
                                    placeholder={I18N.common_searchPlaceholder}
                                    action={{
                                        icon: this.state.searchContractsState.isInProgress()
                                            ? null
                                            : 'search',
                                        onClick: () => this.searchContracts()
                                    }}
                                    onChange={(_, data) =>
                                        this.setState({ searchContractsQuery: data.value })}
                                    onKeyUp={(event) => {
                                        event.preventDefault();
                                        if (event.keyCode === 13)
                                            this.searchContracts();
                                    }}
                                />
                            }
                            content={this.state.searchContractResultMessage}
                            on='click'
                            open={this.state.searchContractResultMessage != null}
                            onClose={() => this.setState({ searchContractResultMessage: null })}
                            position='bottom right'
                            inverted
                            hideOnScroll
                        />
                    </Menu.Item>
                </Menu.Menu>
            )
        } else {
            const specs = this.state.searchCertificatesState.isSuccessful()
                ? this.state.searchCertificatesState.value
                : []
            return (
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <div className='ui action input'>
                            <Dropdown item search selection
                                id='searchDropdown'
                                icon={null}
                                noResultsMessage={null}
                                disabled={this.state.searchCertificatesState.isInProgress()}
                                options={this.props.domainStore.recentSearches
                                    .map((s, i) => ({ key: i, value: s, text: s }))}
                                searchInput={
                                    <Dropdown.SearchInput
                                        autoComplete='off'
                                        placeholder={I18N.common_searchPlaceholder}
                                        value={this.state.searchCertificatesQuery}
                                        disabled={this.state.searchCertificatesState.isInProgress()}
                                        onChange={(_, data) =>
                                            this.setState({ searchCertificatesQuery: data.value })}
                                        onKeyUp={(event) => {
                                            event.preventDefault();
                                            if (event.keyCode === 13)
                                                this.searchCertificates();
                                        }} />
                                }
                                onChange={(event, data) => {
                                    if (event instanceof KeyboardEvent && event.code === 'Enter') {
                                        return this.searchCertificates()
                                    }
                                    if (event.type === 'click' && data.value) {
                                        this.setState({ searchCertificatesQuery: data.value as string })
                                    }
                                }} >
                            </Dropdown>
                            <Popup
                                trigger={
                                    <Button icon='search'
                                        loading={this.state.searchCertificatesState.isInProgress()}
                                        disabled={this.state.searchCertificatesState.isInProgress()}
                                        onClick={(e) => this.searchCertificates()} />
                                }
                                content={this.state.searchCertificateResultMessage}
                                on='click'
                                open={this.state.searchCertificateResultMessage != null}
                                onClose={() => this.setState({ searchCertificateResultMessage: null })}
                                position='bottom right'
                                inverted
                                hideOnScroll
                            />
                        </div>
                        <Button icon='print' style={{ marginLeft: 5 }}
                            disabled={specs.length === 0}
                            loading={this.state.printCertificatesAfterSync.length > 0}
                            onClick={(e) => this.printSertificatesSpecs(specs, true)} />
                    </Menu.Item>
                </Menu.Menu>
            )
        }
    }

    render() {
        let secret = this.state.auth.secret

        return (
            <Menu borderless fixed='top'>
                <Modal basic size='mini' dimmer='blurring'
                    open={secret == null || secret.trim() == ''}>
                    <Header icon='key' content='Enter secret key' />
                    <Modal.Content>
                        <Input fluid focus type='password'
                            onChange={(_, data) => secret = data.value} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green'
                            onClick={() => this.props.domainStore.setSecret(secret)}>Save</Button>
                    </Modal.Actions>
                </Modal>
                <Container>
                    <Menu.Item className='hideOnMobile logoItem'>
                        <Image size='mini' src='images/app-logo-small.png' />
                    </Menu.Item>
                    <Menu.Item header className="hideOnMobile">
                        {I18N.common_appName}
                    </Menu.Item>
                    <Dropdown
                        item
                        icon={null}
                        trigger={
                            <span>
                                <Icon name='file pdf' />
                                <span className="hideOnMobile" style={{ paddingLeft: 5 }}>
                                    {I18N.common_documents}
                                </span>
                            </span>
                        }>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                icon='file pdf outline'
                                active={this.props.history.location.pathname == '/'
                                    || this.props.history.location.pathname == '/certificates'}
                                onClick={() => this.props.history.push('/certificates')}
                                text={I18N.common_certificates} />
                            <Dropdown.Item
                                icon='file pdf outline'
                                active={this.props.history.location.pathname == '/contracts'}
                                onClick={() => this.props.history.push('/contracts')}
                                text={I18N.common_contacts} />
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                        item
                        icon={null}
                        trigger={
                            <span>
                                <Icon name='sync' />
                                <span className="hideOnMobile" style={{ paddingLeft: 5 }}>
                                    {I18N.common_sync}
                                </span>
                            </span>
                        }>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                icon='sync'
                                active={this.props.history.location.pathname == '/sync'}
                                onClick={() => this.props.history.push('/sync')}
                                text={I18N.common_syncCertificates} />
                            <Dropdown.Item
                                icon='upload'
                                active={this.props.history.location.pathname == '/upload'}
                                onClick={() => this.props.history.push('/upload')}
                                text={I18N.common_uploadContracts} />
                        </Dropdown.Menu>
                    </Dropdown>
                    {this.renderToolsPanel()}
                </Container>
            </Menu >
        )
    }
}
export const AppBar = withRouter(AppBarComponent as any)