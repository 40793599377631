/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */
import * as React from 'react';

import { Message, Segment, Image } from 'semantic-ui-react'

import { AsyncState, AsyncError, AsyncErrorSevirity } from '../../models/async.models'
import { I18N } from '../../i18n';

export class BaseComponent<P, S> extends React.Component<P, S> {
    protected disposables: Function[]

    componentWillUnmount() {
        if (this.disposables)
            this.disposables.forEach(dispose => dispose());
    }

    protected asyncStateWidget<V>(state: AsyncState<V>, callbacks: {
        onFail?: (error: any) => JSX.Element,
        onProgress?: (value: V) => JSX.Element,
        onSuccess?: (value: V) => JSX.Element,
        onValue?: (value: V) => JSX.Element,
        orElse?: () => JSX.Element
    }): JSX.Element {
        if (state != null) {
            if (state.value != null && callbacks.onValue != null)
                return callbacks.onValue(state.value);
            if (state.isSuccessful() && callbacks.onSuccess != null)
                return callbacks.onSuccess(state.value);
            if (state.isInProgress() && callbacks.onProgress != null)
                return callbacks.onProgress(state.value);
            if (state.isFailed() && callbacks.onFail != null)
                return callbacks.onFail(state.error);
        }
        if (callbacks.orElse != null)
            return callbacks.orElse();
        return null
    }

    protected asyncErrorMessage(error: AsyncError) {
        const content = I18N.asyncError(error);
        return (
            <Message
                negative={error.sevirity == AsyncErrorSevirity.ERROR}
                warning={error.sevirity == AsyncErrorSevirity.WARNING}
                icon='warning circle'
                floating
                header={content.title}
                content={content.subtitle}
            />
        )
    }

    protected loadingParagraph() {
        return (
            <Segment loading>
                <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
            </Segment>
        )
    }
}