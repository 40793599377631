/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

import { observable, action } from 'mobx'

import { AsyncState } from '../models/async.models';
import {
    SyncState, Certificate, SyncPhase, Contract,
    UploadState, UploadPhase, Auth
} from '../models/domain.models';
import { Api } from '../api';
import { NetworkProblems } from '../api/api.errors';
import { Observable } from 'rxjs';


export class DomainStore {
    @observable
    public auth: Auth
    @observable
    public syncCertificatesState: AsyncState<SyncState[]> = AsyncState.create();
    @observable
    public searchCertificatesState: AsyncState<Certificate[]> = AsyncState.create();
    @observable
    public searchContractsState: AsyncState<Contract[]> = AsyncState.create();
    @observable
    public uploadContractsState: AsyncState<UploadState[]> = AsyncState.create();
    @observable
    public recentSearches: string[] = ['50.00mm×6.5']
    public syncHistory: { code: string, periods: any[] }[] = []


    private api: Api;
    private batchUploading: boolean = false

    constructor(api: Api) {
        this.api = api;

        this.setSecret(localStorage.getItem('X-Secret'))
        this.updateSyncHistory()
        const certificateSearchResult = sessionStorage.getItem('certificateSearchResult')
        if (certificateSearchResult) {
            this.searchCertificatesState = AsyncState.success(JSON.parse(certificateSearchResult))
        }
        const contractsSearchResult = sessionStorage.getItem('contactSearchResult')
        if (contractsSearchResult) {
            this.searchContractsState = AsyncState.success(JSON.parse(contractsSearchResult))
        }

        const recentSearches = localStorage.getItem('recentSearches')
        if (recentSearches)
            this.recentSearches = recentSearches.split('\n')

        api.domain.listenSyncCertificatesProgress()
            .bufferTime(500)
            .filter(dataList => dataList.length > 0)
            .subscribe(
                (dataBuf) => {
                    var newState: AsyncState<SyncState[]>;
                    for (let i = 0; i < dataBuf.length; i++) {
                        const data = dataBuf[i]
                        if (data.type == 'SYNC_ERROR') {
                            newState = AsyncState.failed(new NetworkProblems())
                            break
                        }
                        if (data.type == 'SYNC_COMPLETE') {
                            newState = AsyncState.success(data.results)
                            break
                        }
                        if (data.type == 'SYNC_PROGRESS') {
                            if (!newState) {
                                var newState = AsyncState.inProgress(
                                    this.syncCertificatesState.isInProgress()
                                        ? this.syncCertificatesState.value.slice()
                                        : [])
                            }
                            data.results.forEach(state => {
                                const stateIdx = newState.value.findIndex(item =>
                                    item.contractNumber === state.contractNumber)
                                if (stateIdx >= 0) {
                                    newState.value[stateIdx] = state
                                } else {
                                    newState.value.push(state)
                                }
                            })
                        }
                    }
                    if (newState)
                        this.syncCertificatesState = newState
                })

        api.domain.listenUploadContractsProgress()
            .bufferTime(500)
            .filter(dataList => dataList.length > 0)
            .subscribe(
                (dataBuf) => {
                    var newState: AsyncState<UploadState[]>;
                    for (let i = 0; i < dataBuf.length; i++) {
                        const data = dataBuf[i]
                        if (data.type == 'UPLOAD_ERROR') {
                            newState = AsyncState.failed(new NetworkProblems())
                            break
                        }
                        if (data.type == 'UPLOAD_COMPLETE') {
                            if (!this.batchUploading) {
                                newState = AsyncState.success(
                                    this.uploadContractsState.isInProgress()
                                        ? this.uploadContractsState.value
                                        : data.results)
                                break
                            }
                        }
                        if (data.type == 'UPLOAD_PROGRESS') {
                            if (!newState) {
                                var newState = AsyncState.inProgress(
                                    this.uploadContractsState.isInProgress()
                                        ? this.uploadContractsState.value.slice()
                                        : [])
                            }
                            data.results.forEach(state => {
                                const stateIdx = newState.value.findIndex(item =>
                                    item.contractTimestamp === state.contractTimestamp)
                                if (stateIdx >= 0) {
                                    newState.value[stateIdx] = state
                                } else {
                                    newState.value.push(state)
                                }
                            })
                        }
                    }
                    if (newState)
                        this.uploadContractsState = newState
                })
    }

    @action
    updateSyncHistory() {
        this.getSyncHistory()
        const syncHistory = JSON.parse(sessionStorage.getItem('syncHistoryResults'))
        if (syncHistory)
            this.syncHistory = Object.keys(syncHistory)
                .map(key => ({
                    code: key,
                    periods: syncHistory[key],
                }))
    }

    @action
    setSecret(secret: string) {
        this.auth = {
            secret: secret,
            onRejected: () => {
                localStorage.removeItem('X-Secret')
            }
        }
        localStorage.setItem('X-Secret', secret)
    }

    @action
    saveSyncHistory(code: string, query: string) {
        // const syncHistory = this.syncHistory.slice()
        this.api.domain.saveSyncHistory(this.auth, code, query)
             .subscribe(
                (data) => {
                },
                (error) => {
                    console.log(error)
                });
    }

    @action
    getSyncHistory() : any {
        this.api.domain.getSyncHistory(this.auth)
        .subscribe(
           (data) => {
            sessionStorage.setItem('syncHistoryResults', JSON.stringify(data))
           },
           (error) => {
               console.log(error)
           });
    }

    @action
    searchCertificates(query?: string): void {
        console.log('searchCertificates()', query);

        this.searchCertificatesState = AsyncState.inProgress();

        if (query && query.length) {
            const recentSearches = [query].concat(this.recentSearches.filter(s => s != query))
            this.recentSearches = recentSearches.splice(0, 10)
            localStorage.setItem('recentSearches', this.recentSearches.join('\n'))
        }

        this.api.domain.searchCertificates(this.auth, query)
            .subscribe(
                (data) => {
                    this.searchCertificatesState = AsyncState.success(data)
                    sessionStorage.setItem('certificateSearchResult', JSON.stringify(data))
                },
                (error) => {
                    this.searchCertificatesState = AsyncState.failed(error)
                });
    }

    @action
    syncCertificates(numbers: string[]): void {
        console.log('syncCertificates()', numbers);

        this.syncCertificatesState = AsyncState.inProgress(
            numbers.map(n => {
                const state: SyncState = {
                    contractNumber: n,
                    phase: SyncPhase.AWAITS
                }
                return state
            }));

        this.api.domain.syncCertificates(this.auth, numbers)
            .subscribe(
                (_) => null,
                (error) => {
                    this.syncCertificatesState = AsyncState.failed(error)
                });
    }

    @action
    searchContracts(query?: string): void {
        console.log('searchContracts()', query);

        this.searchContractsState = AsyncState.inProgress();

        this.api.domain.searchContracts(this.auth, query)
            .subscribe(
                (data) => {
                    this.searchContractsState = AsyncState.success(data)
                    sessionStorage.setItem('contactSearchResult', JSON.stringify(data))
                },
                (error) => {
                    this.searchContractsState = AsyncState.failed(error)
                });
    }

    uploadContracts(files: File[]) {
        console.log('uploadContracts()');

        this.uploadContractsState = AsyncState.inProgress(
            files.map(file => {
                const state: UploadState = {
                    contractTimestamp: parseInt(file.name.slice(0, -4)),
                    phase: UploadPhase.AWAITS
                }
                return state
            }));

        this.batchUploading = files.length > 1000
        Observable.from(files)
            .bufferCount(1000)
            .flatMap(batch => this.api.domain.uploadContracts(this.auth, batch), 1)
            .subscribe(
                (_) => null,
                (error) => {
                    this.uploadContractsState = AsyncState.failed(error)
                }, () => {
                    this.batchUploading = false
                });
    }
}