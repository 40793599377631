/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

export class NetworkProblems extends Error { }
export class ServerError extends Error { }
export class AuthError extends Error { }

export enum ApiErrorCode { }

export class ApiError extends Error {
  readonly code: ApiErrorCode;

  constructor(code: ApiErrorCode) {
    super();
    this.code = code;
  }
}
