/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */

import * as React from 'react';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Table, Progress, Pagination } from '../../../node_modules/semantic-ui-react';

import { BaseComponent } from '../components'
import { DomainStore } from '../../stores/domain.store';
import { AsyncState } from '../../models/async.models';
import {
    SyncState, SyncPhase, isSyncPhaseFailed,
    isSyncPhaseInProgress
} from '../../models/domain.models';
import { I18N } from '../../i18n';
import { C } from '../../c';

type Props = {
    domainStore: DomainStore
}
type State = {
    syncState: AsyncState<SyncState[]>
    page: number
}

@inject('domainStore')
@observer
export class SyncContractsScreen extends BaseComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            syncState: props.domainStore.syncCertificatesState,
            page: 1,
        }
    }

    componentDidMount() {
        document.title = I18N.common_syncCertificates

        this.disposables = [
            reaction(
                () => this.props.domainStore.syncCertificatesState,
                (syncState) => {
                    if (this.state.syncState.isInProgress() &&
                        syncState.isSuccessful()) {
                        const successCount = syncState.value.filter(s =>
                            s.phase === 'COMPLETE').length
                        alert(I18N.syncCertificate_syncFinishedMessage(successCount))
                    }
                    this.setState({ syncState });
                }),
        ];
    }

    render() {
        return this.asyncStateWidget(this.state.syncState, {
            onFail: (error) => this.asyncErrorMessage(error),
            onValue: (value) => this.renderTable(
                value.sort((s1, s2) => s1.contractNumber.localeCompare(s2.contractNumber))),
        });
    }

    private renderTable(states: SyncState[]) {
        const pageCount = Math.ceil(states.length / C.SYNC_STATES_PER_PAGE)
        const footer = pageCount > 1 ? (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='8' className='right aligned'>
                        <Pagination
                            size='tiny'
                            activePage={this.state.page}
                            totalPages={pageCount}
                            nextItem={false}
                            prevItem={false}
                            siblingRange={1}
                            boundaryRange={0}
                            ellipsisItem={false}
                            onPageChange={(_, data) => this.setState({
                                page: data.activePage as number
                            })} />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        ) : null

        const startIndex = (this.state.page - 1) * C.SYNC_STATES_PER_PAGE
        return (
            <Table celled size='small'>
                <Table.Header className='hideOnMobile'>
                    <Table.Row>
                        <Table.HeaderCell>{I18N.certificate_contractNumber}</Table.HeaderCell>
                        <Table.HeaderCell width='5'>{I18N.syncCertificate_phase}</Table.HeaderCell>
                        <Table.HeaderCell width='7'>{I18N.syncCertificate_progress}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        states
                            .slice(startIndex, startIndex + C.SYNC_STATES_PER_PAGE)
                            .map(state => (
                                <Table.Row key={'syncRow' + state.contractNumber}>
                                    <Table.Cell>{state.contractNumber}</Table.Cell>
                                    <Table.Cell>
                                        {I18N.syncCertificate_fromSyncPhase(state.phase)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Progress
                                            color={state.phase == SyncPhase.AWAITS ? 'grey' : 'yellow'}
                                            success={state.phase == SyncPhase.COMPLETE}
                                            error={isSyncPhaseFailed(state.phase)}
                                            active={isSyncPhaseInProgress(state.phase)}
                                            total={5}
                                            value={
                                                state.phase == SyncPhase.AWAITS ? 0 :
                                                    state.phase == SyncPhase.PARSING_WEBPAGE ? 2 :
                                                        state.phase == SyncPhase.DOWNLOADIN_PDF ? 3 :
                                                            state.phase == SyncPhase.PARSING_PDF ? 4 :
                                                                state.phase == SyncPhase.STORING_DATA ? 5 : 6
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))
                    }
                </Table.Body>
                {footer}
            </Table>
        )
    }
}