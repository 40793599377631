/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */
import { ServerError, NetworkProblems, ApiError, ApiErrorCode, AuthError } from '../api/api.errors';

export enum AsyncStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export enum AsyncErrorType {
    NETWORK_PROBLEMS = 'NETWORK_PROBLEMS',
    SERVER_ERROR = 'SERVER_ERROR',
    AUTH_ERROR = 'AUTH_ERROR',
    APP_ERROR = 'APP_ERROR',
    API_ERROR = 'API_ERROR',
}

export enum AsyncErrorSevirity {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    EXPECRED = 'EXPECRED',
}

export class AsyncError extends Error {
    readonly type: AsyncErrorType
    readonly sevirity: AsyncErrorSevirity
    readonly error?: any

    constructor(type: AsyncErrorType, sevirity: AsyncErrorSevirity, error?: any) {
        super()
        this.type = type
        this.sevirity = sevirity
        this.error = error
    }

    static networkProblems = () =>
        new AsyncError(AsyncErrorType.NETWORK_PROBLEMS, AsyncErrorSevirity.WARNING);
    static serverError = () =>
        new AsyncError(AsyncErrorType.SERVER_ERROR, AsyncErrorSevirity.ERROR);
    static appError = () =>
        new AsyncError(AsyncErrorType.APP_ERROR, AsyncErrorSevirity.ERROR);
    static apiError = (code: ApiErrorCode) =>
        new AsyncError(AsyncErrorType.APP_ERROR, AsyncErrorSevirity.EXPECRED, code);
    static authError = () =>
        new AsyncError(AsyncErrorType.AUTH_ERROR, AsyncErrorSevirity.WARNING);
}

export class AsyncState<V = void> {
    readonly status: AsyncStatus
    readonly value?: V
    readonly error?: AsyncError

    constructor(status?: AsyncStatus, value?: V, error?: AsyncError) {
        this.status = status || AsyncStatus.NEW
        this.value = value
        this.error = error
    }

    static create<V = any>(value?: V): AsyncState<V> {
        return new AsyncState(AsyncStatus.NEW, value)
    }
    static success<V = void>(value?: V): AsyncState<V> {
        return new AsyncState(AsyncStatus.SUCCESS, value)
    }
    static inProgress<V = any>(value?: V): AsyncState<V> {
        return new AsyncState(AsyncStatus.IN_PROGRESS, value)
    }
    static failed<V = any>(error: any, value?: V): AsyncState<V> {
        if (error instanceof NetworkProblems)
            return new AsyncState(AsyncStatus.FAILED, value, AsyncError.networkProblems());
        if (error instanceof ServerError)
            return new AsyncState(AsyncStatus.FAILED, value, AsyncError.serverError());
        if (error instanceof ApiError)
            return new AsyncState(AsyncStatus.FAILED, value, AsyncError.apiError(error.code));
        if (error instanceof AsyncError)
            return new AsyncState(AsyncStatus.FAILED, value, error);
        if (error instanceof AuthError)
            return new AsyncState(AsyncStatus.FAILED, value, AsyncError.authError());

        console.error(error);
        if (error instanceof Error)
            console.log(error.stack);

        return new AsyncState(AsyncStatus.FAILED, value, AsyncError.appError());
    }

    isNew = (): boolean => this.status === AsyncStatus.NEW
    isInProgress = (): boolean => this.status === AsyncStatus.IN_PROGRESS
    isSuccessful = (): boolean => this.status === AsyncStatus.SUCCESS
    isFailed = (): boolean => this.status == AsyncStatus.FAILED
    isUseless = (): boolean => this.isNew() || this.isFailed()

    isEqual(other: AsyncState<V>): boolean {
        return this.status === other.status && this.value === other.value
    }
    isNotEqual(other: AsyncState<V>): boolean {
        return !this.isEqual(other)
    }
}